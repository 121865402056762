@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: 127 17 224;
    --color-secondary: 239 98 108;
    --color-background: 241 242 248;
    --color-light-text: 255 255 255;
    --color-dark-text: 0 0 0;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: rgb(var(--color-dark-text));
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

//loading css

.loading-main {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: (0, 0, 0 0.5);
  z-index: 1000;
}

.loading-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
}

div.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.line {
  position: relative;
  display: inline-block;
  height: 0px;
  border-radius: 5px;
  width: 10px;
  margin: 5px;
  animation: line linear infinite 0.9s;
}

.time-setting-table {
  table,
  th,
  tr,
  td {
    border-collapse: collapse;
  }
}

@keyframes line {
  0% {
    height: 0px;
    margin-top: 60px;
  }

  50% {
    height: 50px;
    margin-top: 10px;
  }

  100% {
    height: 0px;
    margin-top: 60px;
  }
}

div.d1 {
  animation-delay: 0.15s;
}

div.d2 {
  animation-delay: 0.3s;
}

div.d3 {
  animation-delay: 0.45s;
}

div.d4 {
  animation-delay: 0.6s;
}

div.d5 {
  animation-delay: 0.75s;
}

div.d6 {
  animation-delay: 0.9s;
}

div.caption {
  color: rgb(var(--color-primary));
  font-family: "Noto Sans", sans-serif;
}

div.dot {
  display: inline-block;
  margin: 0px;
  padding: 0px;
  animation: dot 1s infinite linear;
}

div.dot:nth-child(2) {
  animation-delay: 0.2s;
}

div.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}
